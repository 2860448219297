import React, { useState } from 'react';
import './Workshops.scss';
import { ScrollTop } from 'primereact/scrolltop';
import Header from '../../../NewLandingPage/Home/Header/Header';
import Footer from '../../../NewLandingPage/Home/Footer/Footer';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Helmet } from 'react-helmet';

const Workshops = ({ workshops }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter workshops based on the search term
    const filteredWorkshops = workshops.filter(workshop =>
        workshop.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title> Startup2Launch Workshops | Gain Business Skills Schools Don’t Teach</title>
                <meta name="description" content="Startup2Launch workshops teach hands-on skills in business planning, innovation, and marketing. Gain business skills schools don’t teach—start now!" />
                <link rel="canonical" href="https://www.startup2launch.com/workshops" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content=" Startup2Launch Workshops | Gain Business Skills Schools Don’t Teach" />
                <meta property="og:url" content="https://www.startup2launch.com/workshops" />
                <meta property="og:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
                <meta property="og:description" content="Startup2Launch workshops teach hands-on skills in business planning, innovation, and marketing. Gain business skills schools don’t teach—start now!" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content=" Startup2Launch Workshops | Gain Business Skills Schools Don’t Teach" />
                <meta name="twitter:site" content="@https://www.startup2launch.com/workshops" />
                <meta name="twitter:description" content="Startup2Launch workshops teach hands-on skills in business planning, innovation, and marketing. Gain business skills schools don’t teach—start now!" />
                <meta name="twitter:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
            </Helmet>

            <ScrollTop />
            <Header />
            <div className='workshops-container'>
                <h2>Workshops</h2>
                <div className='workshops-grid grid'>
                    <div className='workshops-left-section col-12 md:col-9'>
                        <div>
                            {filteredWorkshops.map((workshop) => (
                                <div key={workshop.id} className='workshop'>
                                    <div className='w-name'>{workshop.workshopName}<span className="w-tag" ><Tag value={workshop.status}
                                        severity={workshop.statusCode === 'C' ? 'success' : 'info'} /></span></div>
                                    <div className='w-date-time flex'>
                                        <div className='w-date'>{workshop.date}</div>
                                        <div className='w-readtime'>{workshop.read_time}</div>
                                    </div>
                                    <div className='w-description'>
                                        {workshop.description}
                                    </div>
                                    <div className='rm-link'>
                                        <Link
                                            to={
                                                workshop.statusCode === 'C'
                                                    ? `/workshop/${encodeURIComponent(workshop.url.replace(/\s+/g, '-').toLowerCase())}`
                                                    : `/upcomingevents/${encodeURIComponent(workshop.url.replace(/\s+/g, '-').toLowerCase())}`
                                            }
                                            className="read-more-link"
                                        >
                                            {workshop.statusCode === 'C' ? `Read More` : `Register Now`}
                                        </Link> &gt;
                                    </div>
                                </div>
                            ))}
                            {filteredWorkshops.length === 0 && (
                                <div className="no-results">
                                    No workshops found.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='workshops-right-section col-12 md:col-3'>
                        <div className='search'>
                            <div className='search-title'>Search workshop</div>
                            <div className="input-with-icon pt-2" style={{ width: "100%" }}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={searchTerm} onChange={handleSearch} placeholder="Search..." style={{ width: "110%" }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Workshops;
