import React from "react";
import AboutUsBanner from "./Banner/AboutUsBanner";
import OurStory from "./OurStory/OurStory";
import OurValues from "./OurValues/OurValues";
import OurProjects from "./OurProjects/OurProjects";
import OurTeam from "./OurTeam/OurTeam";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Startup2launch | Empowering Your Startup Journey from Ideation to Market Leadership</title>
        <meta name="description" content="Discover how Startup2Launch empowers aspiring entrepreneurs to turn ideas into successful businesses. Learn more about our mission and values." />
        <link rel="canonical" href="https://www.startup2launch.com/aboutus" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Startup2launch | Turn Ideas into Thriving Businesses" />
        <meta property="og:url" content="https://www.startup2launch.com/aboutus" />
        <meta property="og:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
        <meta property="og:description" content="Discover how Startup2Launch empowers aspiring entrepreneurs to turn ideas into successful businesses. Learn more about our mission and values." />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Startup2launch | Turn Ideas into Thriving Businesses" />
        <meta name="twitter:site" content="@https://www.startup2launch.com/aboutus" />
        <meta name="twitter:description" content="Discover how Startup2Launch empowers aspiring entrepreneurs to turn ideas into successful businesses. Learn more about our mission and values." />
        <meta name="twitter:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
      </Helmet>

      <div>
        <Header />
        <AboutUsBanner />
        <OurStory />
        <OurValues />
        <OurProjects />
        <OurTeam />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
