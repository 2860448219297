import React from "react";
import "./Academy.scss";
import AcademyHeader from "./Academy-Header/AcademyHeader";
import AcademyPrograms from "./Programs/AcademyPrograms";
import Features from "./Features/Features";
import AcademyEvents from "./Events/AcademyEvents";
import PastEvents from "./PastEvents/PastEvents";
import { AcademyStudent } from "./Students/AcademyStudent";
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import { Helmet } from "react-helmet";

const Academy = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Startup2launch | Empowering Your Startup Journey from Ideation to Market Leadership</title>
        <meta name="description" content="Learn how to build thriving businesses with Startup2Launch Academy. Gain essential skills in innovation, marketing, and strategy—start today!" />
        <link rel="canonical" href="https://www.startup2launch.com/academy" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Startup2launch | Turn Ideas into Thriving Businesses" />
        <meta property="og:url" content="https://www.startup2launch.com/academy" />
        <meta property="og:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
        <meta property="og:description" content="Join Startup2Launch Academy to gain essential skills in innovation, marketing, and strategy. Start your entrepreneurial journey today!" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Startup2launch | Turn Ideas into Thriving Businesses" />
        <meta name="twitter:site" content="@https://www.startup2launch.com/academy" />
        <meta name="twitter:description" content="Join Startup2Launch Academy to gain essential skills in innovation, marketing, and strategy. Start your entrepreneurial journey today!" />
        <meta name="twitter:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
      </Helmet>

      <div className="noSelect">
        <Header />
        <AcademyHeader />
        <AcademyPrograms />
        <Features />
        <AcademyEvents />
        <PastEvents />
        <AcademyStudent />
        <Footer />
      </div>
    </>
  );
};

export default Academy;
