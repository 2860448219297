import React from "react";
import "./Home.scss";
import Header from "../../NewLandingPage/Home/Header/Header";
import Banner from "./Banner/Banner";
import FrameWork from "./FrameWorkSection/FrameWork";
import Explore from "./ExploreSection/Explore";
import OurBusinesses from "./OurBusinesses/OurBusinesses";
import ClientReview from "./ClientReview/ClientReview";
import Footer from "./Footer/Footer";
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Startup2launch | Empowering Your Startup Journey from Ideation to Market Leadership</title>
                <meta name="description" content="Transform your tech idea into a successful business in just 100 days with Startup2Launch. Expert guidance, proven strategies, and resources to help entrepreneurs build, grow, and succeed. Start your journey today." />
                <link rel="canonical" href="https://www.startup2launch.com/" />
                <meta name="robots" content="index, follow" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content="Startup2launch | Empowering Your Startup Journey from Ideation to Market Leadership" />
                <meta property="og:url" content="https://www.startup2launch.com/" />
                <meta property="og:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
                <meta property="og:description" content="Turn your idea into a successful business in 100 days. Expert guidance and proven strategies await!" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Startup2launch | Empowering Your Startup Journey from Ideation to Market Leadership" />
                <meta name="twitter:site" content="@https://www.startup2launch.com/" />
                <meta name="twitter:description" content="Turn your idea into a successful business in 100 days. Expert guidance and proven strategies await!" />
                <meta name="twitter:image" content="https://staticfile.startup2launch.com/S2L-Logo.svg" />
            </Helmet>

            <Header />
            <Banner />
            <FrameWork />
            <Explore />
            <OurBusinesses />
            <ClientReview />
            <Footer />
        </>
    )
}

export default Home;